<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #heading>
      <SecurityLogo />
    </template>

      <template #card>
        <div class="">
          <div class="text-center">
            <h1>Multi-factor Authentication</h1>
          </div>
          <div class="">
            <p class="m-0 p-0 text-center">
              Kindly enter the otp received via {{ mfa_method }}
            </p>
          </div>
        </div>
        <div class="pt-2">
          <validation-observer ref="authenticateForm" #default="{invalid}">
            <b-form @submit.prevent="verify">
              <!--                username -->
              <b-form-group label-for="code">
                <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required"
                >
                  <div class="exxtra-input">
                          <span class="leading">
                        <PasswordLock />
                      </span>
                    <input id="login-username" v-model="code" type="number" class="otp"
                           placeholder="* * * * *"
                    >
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

                    <div>
                      <b-button
                          class="py-1"
                          type="submit"
                          variant="primary"
                          block
                          :disabled="invalid"
                      >
                        Verify
                      </b-button>
                    </div>
                  </b-form>
                </validation-observer>
                <div v-if="mfa_method !== 'app'" class="text-center pt-1">
                  <div class="">
                    <p v-if="isResendOTPEnabled" class="font-weight-bold border border-bottom m-0 p-0" @click="resendMfaOTP"> Resend Code
                    </p>
                  </div>

          <div v-if="!isResendOTPEnabled">
            <p class="m-0 p-0">
              Resend verification code after <span class="text-success">{{ timerString }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="text-center">
        <p class="pt-2">
          <b-link class="font-weight-bold" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
          </b-link>
        </p>
      </div>
    </template>

  </AuthWrapper>
</b-overlay>
</template>

<script>
import EmailIcon from "@/assets/svg/emailIcon.svg";
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required
} from "@core/utils/validations/validations";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import {
  BCol,
  BImg,
  BRow,
  BLink,
  BForm,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend
} from "bootstrap-vue";
import {
  get
} from "lodash";
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    AuthenticatorAppIcon,
    EmailIcon,
    TextMessageIcon,
    PasswordLock,
    Rectangles,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mfa_method: '',
      loading: false,
      timerString: '00:00',
      code: '',
      // validation rules
      required
    }
  },
  computed: {
    isResendOTPEnabled() {
      return this.timerString === "00:00";
    }
  },
  created() {
    this.retrieveMfaMethod();
    this.updateTimeRemaining()
  },
  methods: {
    async verify() {
      try {
        const payload = {
          mfa_method: this.mfa_method,
          token: this.code
        }
        const response = await this.useJwt()
          .mfaService
          .verifyAndActivate(payload);
        const response_data = response.data.data;

        this.$router.push({
            name: "security-mfa-recovery-codes",
            params: {
              recovery_codes: response_data.recovery_codes
            }
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'MFA Activation Successful',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Kindly store the available recovery codes'
              },
            });
          })
          .catch(() => {});
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Mfa Activation Failed',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async resendMfaOTP() {
      try {
        const payload = {
          mfa_method: this.mfa_method
        }
        await this.useJwt()
          .mfaService
          .resendMfaOtp(payload);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Resent Successfully`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `New verification OTP has been sent`,
          },
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      }
    },
    startTimer(duration) {
      let timer = duration;
      let minutes;
      let seconds;

      const interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        // eslint-disable-next-line no-plusplus
        if (--timer < 0) {
          clearInterval(interval);
        }

        this.timerString = minutes + ":" + seconds;
      }, 1000);
    },
    updateTimeRemaining() {
      const threeMinutes = 60 * 3;
      this.startTimer(threeMinutes);
    },
    retrieveMfaMethod() {
      const {
        mfa_method
      } = this.$route.params;
      if (mfa_method) {
        this.mfa_method = mfa_method;
      } else {
        this.$router.replace({
            name: "security-mfa",
          })
          .then(() => {})
          .catch(() => {});
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
